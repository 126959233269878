/* CSS Reset */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Outfit", sans-serif;
}

:root {
  --primary: #d8363d;
}

html {
  width: 100vw;
  overflow-x: hidden;
}

body {
  background-color: #f6f6f6;
  width: 100vw;
  overflow-x: hidden;
}

.wrapper {
  position: relative;
}

a {
  text-decoration: none;
}

#root {
  position: relative;
}

header {
  position: absolute;
  width: 90%;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}

header .logo {
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -3px;
}

header .logo img {
  width: 90px;
}

header nav {
  width: 60%;
  text-align: right;
}

header nav ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  list-style: none;
  margin: auto;
}

header nav ul li a {
  text-decoration: none;
  color: #000;
}

header .btns {
  width: 14%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
}

header .btns i {
  font-size: 20px;
  color: var(--primary);
  margin-right: 10px;
}

.currency-popup {
  position: fixed;
  top: 0;
  right: 0;
  display: none;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.currency-popup .currency {
  position: relative;
  background-color: #fff;
  padding: 25px;
  border-radius: 15px;
  width: 40%;
  height: 180px;
  box-shadow: 1px 1px 10px 0px #a3a3a39a;
}
.currency-popup .currency p {
  font-size: 22px;
  font-weight: 500;
}
.currency-popup .currency select {
  width: 100%;
  padding: 10px;
  border: 1.5px solid #838181;
  border-radius: 10px;
}
.currency-popup .currency select:focus {
  border: 1.5px solid #838181;
  outline: none;
}
.currency-popup.active {
  display: flex;
}
.currency-popup .currency-close {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 25px;
  width: 25px;
  text-align: center;
  vertical-align: middle;
  background-color: var(--primary);
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
}
.popup {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  display: none;
}

.my-modal {
  width: 40%;
  height: 50%;
  background-color: #fff;
  border-radius: 10px;
}

main {
  min-height: 80vh;
}

main.book {
  padding-top: 10%;
}

.hero {
  width: 100%;
  height: 100vh;
  /* background: url(img/slide-bg.png); */
  background-size: cover;
  display: flex;
  align-items: center;
  color: #fff;
  padding-top: 3%;
}

.hero h2 {
  color: #fff;
  font-size: 42px !important;
  font-weight: 500;
  line-height: 52px;
  margin-bottom: 20px;
  margin-top: 30px;
}

.hero p {
  font-size: 18px;
  margin-bottom: 0;
}

.hero p:nth-child(2) {
  font-weight: 600;
}

.hero .hero-features {
  margin-top: 30px;
  margin-bottom: 30px;
}

.hero .hero-features div {
  text-align: center;
  padding: 0;
  margin-bottom: 30px;
}

.hero .hero-features div:nth-child(1) p {
  border-right: 1px solid #383838ce;
}
.hero .hero-features div img {
  width: 80px;
}

.hero .hero-features div p {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1px;
  padding-top: 8px;
  text-transform: uppercase;
}

.hero a {
  text-decoration: none;
  color: #fff;
  background-color: var(--primary);
  padding: 15px 30px;
}

h2 span {
  color: var(--primary);
}

.about {
  /* padding: 60px 25%;
  text-align: center; */
  padding: 60px 0;
}

.about img {
  width: 100%;
  height: 380px;
  object-fit: contain;
  object-position: bottom;
}

.about .col-6 {
  margin: auto;
}

.about h3 {
  margin-bottom: 0;
}

.about p {
  margin: 10px 0;
  line-height: 24px;
  font-size: 16px;
}
.about .col-6 ul{
  margin-bottom: 0;
}
h3 {
  font-size: 42px;
  margin-bottom: 20px;
}

h3 span, p span.colored {
  color: var(--primary);
}

p.colored {
  font-size: 18px;
  color: var(--primary);
  font-weight: 500;
}

.products {
  padding: 20px 0 40px;
  gap: 2%;
  justify-content: center;
}

.product {
  box-shadow: 0 0 4px 2px #00000015;
  width: 32%;
  padding: 20px;
  padding-bottom: 30px;
  margin-bottom: 20px;
  height: 200px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

/* .row.products .product:nth-child(4) , .row.products .product:nth-child(5) {
  width:49%;
} */

.product img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  margin-bottom: 20px;
}

.pro-title {
  font-size: 22px;
  font-weight: 500;
  color: var(--primary);
  margin-bottom: 10px;
}

.price {
  color: var(--primary);
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 20px;
}

.buy-now {
  padding: 10px;
  background-color: #000;
  margin-top: 20px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  transition: all 0.2s ease-in-out;
}

.product:hover .buy-now {
  background-color: var(--primary);
}

.feature {
  padding: 60px 0;
}

.feature .temp-bg-feature {
  margin: auto;
  width: 100%;
  height: 320px;
  background-size: contain;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.temp-bg-feature.mob{
display: none;
}
.feature .container .row {
  margin: 40px 0;
  /* margin-top: -40px; */
}

/* .feature .col:nth-child(3),
.feature .col:nth-child(7) {
  margin-top: 20px;
  margin-top: 15%;
} */

.numberBox {
  background-color: var(--primary);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin: auto;
}

.num-1 {
  position: relative;
  margin-bottom: -15px;
  z-index: 2;
}

.num-2 {
  position: relative;
  margin-top: -15px;
  z-index: 2;
}

.num-3 {
  position: relative;
  margin-bottom: -15px;
  z-index: 2;
}

.num-4 {
  position: relative;
  margin-top: -15px;
  z-index: 2;
}

.icon-box {
  font-size: 55px;
  color: var(--primary);
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px dotted #000;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.feature p {
  text-align: center;
}

.feature .col-1 {
  padding: 0;
}

.feature .col-1 img {
  width: 100%;
}

/* .feature .col-1 {
  transform: rotate(30deg);
  margin-top: 80px;
} */

/* .feature .col-1:nth-child(4) {
  transform: rotate(-34deg);
} */

.benifits {
  padding: 60px 0;
}

.benifits h3 {
  margin-bottom: 30px;
}

/* .benifits .container {
  padding: 20px;
} */

.benifits .container .row img.mid-img {
  width: 100%;
  border-radius: 20px;
}

.benifit {
  display: flex;
  margin-bottom: 20px;
  /* flex-direction: column; */
}

.benifits .col-4:nth-child(1) .benifit {
  flex-direction: row-reverse;
}

.benifits .col-4:nth-child(2) {
  margin: auto;
}

.benifit .icon {
  align-items: center;
  background-color: #d8363d;
  background-color: var(--primary);
  border-radius: 80px;
  display: flex;
  max-height: 80px;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-right: 20px;
  max-width: 86px !important;
  overflow: hidden;
  padding: 30px;

}
.col-4:nth-child(1) .benifit:nth-child(1) .icon, .col-4:nth-child(3) .benifit:nth-child(1) .icon{
  padding: 0px;
  border-radius: 0px;
  background-color: transparent;
  /* max-width: 80px !important; */
  overflow: visible;

}
.benifit .icon img {
  width: 45px;
  filter: invert(1);
}
.col-4:nth-child(1) .benifit:nth-child(1) .icon img, .col-4:nth-child(3) .benifit:nth-child(1) .icon img {
  width: 80px;
  filter: invert(0);
}
.benifit h4 {
  color: var(--primary);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}

.CTA {
  padding: 40px 0;
}

.CTA .container {
  height: 430px;
  /* background: url(img/CTA-bg.png) no-repeat center; */
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 30px;
  background-position: center;
}

.CTA .container::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 30px;
}

.CTA .row {
  width: 100%;
}

.CTA .row .col-12 {
  position: relative;
  z-index: 9;
}

.CTA .row .col-12 h3 {
  color: #fff;
  font-size: 34px;
}

.CTA .row .col-12 p {
  color: #fff;
  font-size: 18px;
}

.CTA .row .col-6 a {
  text-decoration: none;
  color: #fff;
  font-size: 18px;
  padding: 10px 40px;
  background-color: #000;
}

footer {
  padding: 40px 0;
  background-color: #121212;
}

footer .container {
  /* background-color: #fff; */
  padding: 40px 20px;
}
footer .row{
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}
footer .row .col-4{
  text-align: center;
}
footer .row .col-4 img{
  width: 150px;
}
footer .container h4 {
  font-size: 18px;
  font-weight: 400;
  color: var(--primary);
}

footer .container ul {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  padding: 0;
  gap: 15px;
  justify-content: center;
}

footer .container ul li a {
  text-decoration: none;
  color: #fff;
}

footer .container .socials {
  display: flex;
  gap: 15px;
  justify-content: center;
}

footer .container .socials i {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 18px;
  background-color: var(--primary);
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.copyright p {
  text-align: center;
  margin: 30px 0 0;
  color: #fff;
}

/* book page */
.breadcrumb {
  font-size: 14px;
  color: #474f54;
}

.breadcrumb a {
  text-decoration: none;
  color: #474f54;
}

.tabs {
  text-align: center;
  margin-top: 40px;
  color: #abacad;
}

.tab-1 {
  margin-bottom: 60px;
}

.tabs .row {
  gap: 5.3%;
  justify-content: center;
}

.tabs .col-4 {
  width: 28%;
  padding-bottom: 15px;
  border-bottom: 3px solid #abacad;
  margin-bottom: 40px;
}

.tabs .col-4.active {
  border-bottom: 3px solid var(--primary);
  color: var(--primary);
}

.tabs i {
  font-size: 24px;
}

.tabs p {
  font-size: 14px;
  margin-top: 5px;
}

.delivery_info .fields {
  padding: 25px;
  background-color: #fff;
  margin-bottom: 20px;
}

.delivery_info .fields label {
  display: block;
  font-size: 18px;
  margin-bottom: 5px;
  color: #5a5a5a;
}

.delivery_info .fields input,
.delivery_info .fields select {
  width: 100%;
  padding: 8px;
  color: #abacad;
  border: 1px solid #abacad;
  border-radius: 3px;
}

.delivery_info .fields input:focus,
.delivery_info .fields select:focus {
  outline: none;
}

.delivery_info .options {
  padding: 50px;
  background-color: #fff;
  margin-bottom: 20px;
}

/* .delivery_info .options .col-7 {
  padding-right: 5%;
  border-right: 1px solid #a3a3a3;
} */

.delivery_info .options p.step {
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 18px;
}

.delivery_info .options p.step span {
  font-size: 20px;
  color: var(--primary);
  margin-right: 5px;
}

/* .delivery_info .options ul {
  list-style-type: none;
  padding-left: 0;
}

.delivery_info .options ul li {
  display: inline-block;
  text-align: center;
  margin-right: 15px;
}

.delivery_info .options ul input[type="radio"][id^="cb"] {
  display: none;
}

.delivery_info .options ul label.check-label {
  padding: 10px;
  display: block;
  position: relative;
  cursor: pointer;
  border: 1px solid #464545;
}

label.check-label:before {
  background-color: white;
  color: white;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: -5px;
  left: -5px;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 28px;
  transition-duration: 0.4s;
  transform: scale(0);
}

label img {
  height: 100px;
  width: 100px;
  transition-duration: 0.2s;
  transform-origin: 50% 50%;
}

.check-animal:checked + label {
  border-color: #ddd;
  background-color: var(--primary);
}

.check-animal:checked + label:before {
  content: "✓";
  background-color: grey;
  transform: scale(1);
}

.check-animal:checked + label img {
  transform: scale(0.9);
  box-shadow: 0 0 5px #333;
  z-index: -1;
  filter: invert(1);
} */
select {
  width: 100%;
  padding: 8px 25px 8px 5px;
  margin-top: 10px;
  border-radius: 10px;
}
.step-1 select,
#zabiha-type select {
  margin-top: -20px;
  margin-bottom: 20px;
}

.delivery-type,
.step-3 {
  margin-bottom: 20px;
}

.delivery_info .options .step-2 input {
  margin-right: 10px;
}

.delivery_info .options .step-2 input:checked {
  color: var(--primary);
  background-color: var(--primary);
}

/* Optional: Add some styling for better appearance */
.quantity-container {
  position: relative;
}

.quantity-container input:focus {
  outline: none;
}

.quantity-input {
  width: 100%;
  text-align: center;
  padding: 5px 25px 5px 5px;
  /* Adjust padding as needed */
  box-sizing: border-box;
}

.quantity-control {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  color: var(--primary);
  font-size: 12px;
}

.quantity-control i {
  font-size: 1.2em;
}

.increment {
  right: 5px;
}

.decrement {
  left: 5px;
}

.cal-price {
  font-size: 14px;
  text-align: right;
  color: #a3a3a3;
}

.total-price {
  margin-top: 25px;
  color: var(--primary);
  font-weight: 600;
}

.forward button.forward-btn {
  width: 100%;
  border: none;
  margin-top: 20px;
  color: #fff;
  background-color: var(--primary);
  padding: 8px;
}

.cutomer_info .options {
  margin-bottom: 20px;
}
.react-tel-input .form-control {
  width: 100% !important;
  font-size: 16px !important;
  height: auto !important;
}
.react-tel-input {
  padding: 0;
}
.form-control:focus{
  box-shadow: none !important;
}
.row.tab-2 {
  gap: 3%;
}
.row.tab-2 textarea{
  padding: 10px;  
  border: 1px solid #abacad;
  border-radius: 3px;
}
.row.tab-2 textarea:focus{
  outline: none;
}

.row.tab-2 > .col-7 {
  width: 67%;
  background-color: #fff;
  padding: 25px 30px;
}

.row.tab-2 > .col-5 {
  width: 30%;
  background-color: #fff;
  padding: 25px 15px;
}

.cutomer_info .options h2 {
  font-size: 22px;
}

.cutomer_info .options h3.summary {
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: -15px;
}

.cutomer_info .options h3 {
  font-size: 18px;
  margin-top: 20px;
}

.cutomer_info .options .row .row {
  margin-top: 10px;
  gap: 2%;
  align-items: center;
}

.cutomer_info .options .row .row .col-6 {
  width: 49%;
}

.cutomer_info .options .row .row .col-5 {
  width: 39.6%;
}

/* .cutomer_info .options .row .col-5 {
  text-align: center;
} */
.cutomer_info .options .row .col-5 img {
  width: 100%;
  height: 200px;
  object-fit: contain;
  padding: 20px;
  background-color: #e7e5e5;
}

.cutomer_info .options .row .col-5 table {
  margin: auto;
  width: 100%;
  margin-bottom: 20px;
}

.cutomer_info .options .row .col-5 table tr {
  height: 35px;
}

td.title {
  font-size: 14px;
  color: #5a5a5a;
}

.cutomer_info .options .row .col-5 table .data {
  color: var(--primary);
  padding-left: 30px;
}

.cutomer_info label {
  font-size: 18px;
  margin-bottom: 5px;
  color: #5a5a5a;
  padding-left: 0;
}

.cutomer_info input,
.cutomer_info select {
  width: 100%;
  padding: 8px;
  color: #868686;
  border: 1px solid #abacad;
  border-radius: 3px;
}
.if-not{
  margin-bottom: 30px;
}
.if-not p{
  margin-top: 10px;
  margin-bottom: 10px;
}
.tab-3 p{
  margin-bottom: 50px;
}
.cutomer_info input[type="checkbox"],
.cutomer_info input[type="radio"] {
  width: auto;
  margin-right: 10px;
}

.cutomer_info .row.move .col-5,
.cutomer_info .row.move .col-7 {
  padding: 0;
}

.cutomer_info .back {
  width: 100%;
  border: none;
  background-color: #fad502;
  color: #fff;
  padding: 7px;
}

.cutomer_info .continue {
  width: 100%;
  border: none;
  background-color: var(--primary);
  color: #fff;
  padding: 7px;
  display: block;
  text-align: center;
  cursor: pointer;
}

.cutomer_info input:focus,
.cutomer_info select:focus {
  outline: none;
}

.cutomer_info .row.login {
  text-align: right;
  font-size: 14px;
}

.cutomer_info .row.login a {
  color: var(--primary);
}

.amount {
  font-size: 22px;
  color: var(--primary);
}

/* track page css */

.track {
  width: 60%;
  margin: auto;
  margin-top: 40px;
}

.track input {
  width: 100%;
  padding: 12px;
  color: #abacad;
  border: 1px solid #abacad;
  border-radius: 3px;
}

.track input:focus {
  outline: 1px solid var(--primary);
}

.track input[type="submit"] {
  background-color: var(--primary);
  border: none;
  color: #fff;
}

/* confirm page styling */

.tab-3 h3.confirm_head {
  font-size: 34px;
}

#zabiha-type input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

#zabiha-type input[type="radio"] + label {
  border: 1px solid black;
  border-radius: 40px;
  display: inline-block;
  margin: 0.7rem 0.5rem 0.2rem 0;
  padding: 1rem 2rem;
  font-size: 16pxrem;
  letter-spacing: 0.1rem;
  line-height: 1;
  text-align: center;
  transition: border 0.6s ease;
  cursor: pointer;
  position: relative;
}

#zabiha-type input[type="radio"] + label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 40px;
}

#zabiha-type input[type="radio"]:checked + label {
  color: #fff;
  background: var(--primary);
  border: var(--primary);
}

.progressContainer {
  position: relative;
  width: 400px;
  height: 100vh;
  margin: 0 auto;
  padding: 30px;
  color: #000;
  height: auto;
  display: none;
}

.progress {
  position: relative;
  padding: 0 16px 0 3.5rem;
  margin: 30px 0 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  background-color: unset;
  overflow: visible;
  height: auto;
}

.progress__item {
  position: relative;
  counter-increment: list;
  padding-left: 0.5rem;
  opacity: 0.7;
  padding-bottom: 40px;
}

.progress__item:before {
  content: "";
  position: absolute;
  left: -1.969rem;
  top: 0px;
  height: 100%;
  width: 1px;
  border-left: 2px solid var(--primary);
}

.progress__item:after {
  content: "";
  position: absolute;
  top: 0;
  left: -2.5rem;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: #fff;
  color: #000;
  font-weight: 400;
  font-size: 13px;
  line-height: 2rem;
  text-align: center;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress__item:last-child:before {
  border: none;
}

.progress__item.progress__item--completed {
  opacity: 1;
  color: var(--primary);
  fill: var(--primary);
}

.progress__item.progress__item--completed:after {
  content: "";
  font-weight: 400;
  background: #fff;
  background-color: var(--primary);
  border-color: var(--primary);
}

.progress__item.progress__item--active:after {
  background: #fff;
  color: #392a60;
}

.progress__title {
  margin: 0;
  margin-top: -8px;
  font-size: 1.5rem;
}

.progress__info {
  font-size: 13px;
  margin-bottom: 5px;
}

.track-date {
  color: #000;
}

.progress__item i, .progress__item svg {
  font-size: 40px;
  position: absolute;
  left: -38%;
  top: -10%;
}
.progress__item svg {
  width: 60px;
  height: 64px;
  object-fit: cover;
  margin-top: -20px;
  margin-left: -12px;
}

.delivery-type {
  height: 0px;
  overflow: hidden;
  transition: all 0.8s ease-in-out;
}

.delivery-type p {
  margin-bottom: 0;
  /* margin-top: -20px; */
}

.delivery-type select {
  width: 100%;
  padding: 8px 25px 8px 5px;
  margin-bottom: 20px;
}

.delivery-type.show {
  height: 100%;
}

.delivery-cherges {
  display: none;
}

.textBold {
  text-align: right;
  color: var(--primary);
}

.row.alive-slotter {
  display: none;
}

.alive-slotter {
  margin-top: 20px;
  transition: all 0.8s ease-in-out;
}

.alive-slotter p {
  margin-bottom: 5px;
  width: 100%;
  padding: 0;
}

.alive-slotter h3 {
  padding: 0;
  width: 100%;
}

.alive-slotter select {
  width: 100%;
  padding: 8px 25px 8px 5px;
}

.row.alive-slotter.showDelivery {
  display: block;
}

.payment_modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0000006e;
  display: none;
}

.payment_popup {
  position: relative;
  width: 40%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 20px 30px;
}

.payment_popup p {
  margin-bottom: 0px;
}
.bank-details{
  margin-bottom: 10px;
}

.payment_popup form {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.payment_popup form label {
  font-weight: 300;
}

.payment_popup form label span {
  color: var(--primary);
}

.payment_popup form input {
  padding: 3px;
  border: 2px solid #d3d3d3;
}

.payment_popup form input::placeholder {
  color: #d3d3d3;
}

.payment_popup form .continue {
  width: 100%;
  border: none;
  background-color: var(--primary);
  color: #fff;
  padding: 7px;
  margin-top: 10px;
}

.payment_popup_close {
  position: absolute;
  top: 20px;
  right: 20px;
  height: 25px;
  width: 25px;
  text-align: center;
  vertical-align: middle;
  background-color: var(--primary);
  color: #fff;
  border-radius: 50px;
  cursor: pointer;
}

.cow-shares {
  margin-bottom: 30px;
  display: none;
}

.cow-shares.show {
  display: block;
}

.cow-shares p {
  margin-bottom: 0;
}

.cow-shares input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

.cow-shares input[type="radio"] + label {
  border: 1px solid black;
  border-radius: 40px;
  display: inline-block;
  margin: 0.7rem 0.5rem 0.2rem 0;
  padding: 10px 13px;
  font-size: 16px;
  letter-spacing: 0.1rem;
  line-height: 1;
  text-align: center;
  transition: border 0.6s ease;
  cursor: pointer;
  position: relative;
}

.cow-shares input[type="radio"] + label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 40px;
}

.cow-shares input[type="radio"]:checked + label {
  color: #fff;
  background: var(--primary);
  border: var(--primary);
}

.hamburger {
  display: none;
}

.close {
  display: none;
}

.Cow_or_shares {
  margin-bottom: 30px;
  display: none;
}

.Cow_or_shares.show {
  display: block;
}

.Cow_or_shares p {
  margin-bottom: 0;
}

.Cow_or_shares {
  margin-bottom: 30px;
}

.Cow_or_shares input[type="radio"] {
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

.Cow_or_shares input[type="radio"] + label {
  border: 1px solid black;
  border-radius: 40px;
  display: inline-block;
  margin: 0.7rem 0.5rem 0.2rem 0;
  padding: 1rem 2rem;
  font-size: 16pxrem;
  letter-spacing: 0.1rem;
  line-height: 1;
  text-align: center;
  transition: border 0.6s ease;
  cursor: pointer;
  position: relative;
}

.Cow_or_shares input[type="radio"] + label::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-radius: 40px;
}

.Cow_or_shares input[type="radio"]:checked + label {
  color: #fff;
  background: var(--primary);
  border: var(--primary);
}

table.track-table {
  max-width: 100%;
  display: block;
}
.track-table tbody tr {
  display: flex;
  padding: 5px 10px;
}
.track-table.com-2 tbody tr {
  border-bottom: 1px solid #75757562;
  border-left: 1px solid #75757562;
  border-right: 1px solid #75757562;
}
.track-table.com-2 tbody tr:nth-child(1){
  border-top: 1px solid #75757562;
}
.row.tab-2 > .col-5.track{
  width: 50%;
  padding: 25px;
  display: none;
}
table.track-table tbody {
  width: 100%;
  display: block;
}
table.track-table td.title {
  width: 50%;
}
.order-not-found{
  display: none;
  text-align:center;margin-top:30px;
}
.download-receipt {
  text-align: center;
  margin-bottom: 30px;
  display: none;
}
button{
  margin: auto;
  width: 200px;
  background-color: var(--primary);
  border: none;
  color: #fff;
  padding: 12px;
  border-radius: 3px;
}
@media screen and (max-width: 600px) {
  .temp-bg-feature.mob{
    display: block;
    }
    .temp-bg-feature.desk{
      display: none;
      }
  div.row.delivery-cherges{
    flex-direction: row;
  }
  .delivery_info .options {
    padding: 25px 30px;
  }
  .currency-popup .currency{
    width: 90%;
    height: 20%;
  }
  .hero h2 {
    margin-top: 0px;
  }
  .CTA .row .col-12 p {
    font-size: 16px;
  }
  .CTA .container {
    height: auto;
    padding: 40px 10px;
  }
  .CTA .row .col-12 h3 {
    font-size: 26px;
  }
  .feature {
    padding: 0 0 60px;
  }
  .product {
    width: 100% !important;
  }
  .hero .row .col-6 .col-6 {
    padding: 0 !important;
    width: 50%;
  }

  #zabiha-type input[type="radio"] + label {
    width: 100%;
  }

  .CTA {
    padding: 40px 10px;
  }

  .progressContainer {
    padding: 0 30px;
  }

  .progress__title {
    line-height: 28px;
  }

  .progress__item i, .progress__item svg {
    left: -50%;
  }

  .progress {
    padding: 0 16px 0 7.5rem;
  }

  .track form .row .col-8 {
    margin-bottom: 20px !important;
  }

  .track {
    width: 100%;
  }

  .track form .row {
    flex-direction: column;
  }

  .cutomer_info .options .row .row div.col-6 {
    padding: 0;
  }
/* 
  input[type="number"][name="recipient_number"],
  input[type="email"],
  input[type="postal-code"] {
    margin-top: -22px;
  } */

  .row.move .col-5,
  .row.move .col-7 {
    margin-bottom: 20px;
    width: 100% !important;
  }

  .cutomer_info .back {
    display: block;
    text-align: center;
  }

  .cutomer_info .options .row .row .col-6 {
    width: 100%;
    margin-bottom: 20px;
  }

  .cutomer_info .options .row .row {
    margin-top: 25px;
    flex-direction: column;
  }

  .tab-3 h3.confirm_head {
    font-size: 24px;
    margin-bottom: 5px;
    margin-top: 0px;
  }

  .payment_popup {
    width: 92%;
  }

  .row.tab-2 {
    flex-direction: column;
    padding: 10px;
  }

  label img {
    height: 75px;
    width: 75px;
  }

  .delivery_info .options ul li:last-child {
    margin-right: 0;
  }

  .delivery_info .options ul li {
    width: 31%;
    display: inline-block;
    text-align: center;
    margin-right: 10px;
  }

  .tab-1 .row {
    flex-direction: column;
  }

  main.book {
    padding-top: 35%;
  }

  .hamburger {
    display: block;
    width: 8%;
    text-align: right;
    font-size: 18px;
    order: 3;
  }

  .close {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  header {
    position: fixed;
    z-index: 999;
    border-radius: 10px;
    width: 95%;
  }

  header nav {
    position: absolute;
    top: -20px;
    height: 101vh;
    width: 80%;
    right: -100%;
    background-color: #fff;
    padding: 60px 0;
    transition: all 0.8s ease-in-out;
    z-index: 99;
  }

  header nav.show {
    right: -18px;
  }

  header nav ul {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 20px;
  }

  header nav ul li a {
    font-size: 20px;
    margin: 20px 0;
  }

  header .btns {
    width: 25%;
    margin-left: 25%;
  }

  .hero .hero-features div p {
    font-size: 10px;
  }

  .hero h2 {
    font-size: 32px !important;
    line-height: 42px;
  }

  .hero .hero-features div {
    margin-left: 0;
  }

  .hero .row .col-6 {
    padding: 30px !important;
  }

  p.colored {
    font-size: 18px;
  }

  .about h3 {
    font-size: 30px;
  }

  .about {
    padding: 30px 0;
  }

  .about .row {
    flex-direction: column;
  }

  .products {
    flex-direction: column;
    padding: 10px;
  }

  h3 {
    font-size: 28px;
  }

  .feature .temp-bg-feature {
    /* background-image: url(../src/img/features-image.jpg) !important; */
    height: 280px;
    background-size: contain;
  }

  .benifits {
    padding: 30px 0;
  }

  .benifits .row {
    flex-direction: column;
  }

  .CTA .row .col-6 {
    width: 100%;
  }

  .CTA .row .col-6 p {
    margin-bottom: 20px;
  }

  footer .container {
    padding: 20px 20px;
  }

  footer .row {
    flex-direction: column;
  }

  footer .row .col-4,
  .benifits .row .col-4,
  .products .col-4,
  .about .col-6,
  .hero .row .col-6,
  .delivery_info .options .col-7,
  .delivery_info .options .col-5,
  .row.tab-2 > .col-7,
  .row.tab-2 > .col-5,
  .track form .row .col-8,
  .track form .row .col-4 {
    width: 100%;
    margin-bottom: 30px;
  }
  .row.tab-2 > .col-5.track {
    width: 92%;
  }
}

.acc-details{
  color: var(--primary);
  margin-left: 5px;
}